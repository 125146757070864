<template>
	<a-modal v-model="show" title="计划信息" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" @change="changeor" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
								organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="计划类型" label-position="top" required>
						<a-select v-model="formData.categoryId" placeholder="请选择分类">
							<a-select-option v-for="category in CategoryList" :key="category.categoryId"
								:value="category.categoryId">{{
									category.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="16">
					<a-form-model-item label="标题" label-position="top" required>
						<a-input v-model="formData.title"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="4">
					<a-form-model-item label="计划状态" label-position="top" required>
						<a-select v-model="formData.status">
							<a-select-option v-for="(sta, index) in statusList" :key="index" :value="sta">{{ sta
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="4">
					<a-form-model-item label="发布范围" label-position="top" required>
						<a-select v-model="isAlldep">
							<a-select-option :value="1">所有部门</a-select-option>
							<a-select-option :value="2">指定部门</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15" v-if="isAlldep != 1">
				<a-col span="24">
					<a-form-model-item label="发布范围部门" label-position="top">
						<select-depart :value="formData.scopeDepIds" multiple
							@input="e => (formData.scopeDepIds = e)"></select-depart>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label="批注领导" label-position="top">
						<select-staff :names="formData.leaderName" :value="formData.scopeLeaderIds" multiple
							@input="e => (formData.scopeLeaderIds = e)"></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label="负责人" label-position="top">
						<select-staff :names="formData.managerName" :value="formData.scopeMasterIds" multiple
							@input="e => (formData.scopeMasterIds = e)"></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="24">
					<a-form-model-item label="参与人员" label-position="top">
						<select-staff :value="formData.scopeStaffIds" :names="formData.staffNames" multiple
							@input="e => (formData.scopeStaffIds = e)"></select-staff>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">

				<a-col span="8">
					<a-form-model-item label="开始日期" label-position="top">
						<a-date-picker v-model="formData.startDate" show-time type="date" placeholder="请输入开始日期"
							format="YYYY-MM-DD"></a-date-picker>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="结束日期" label-position="top">
						<a-date-picker v-model="formData.overDate" format="YYYY-MM-DD" show-time type="date"
							placeholder="请输入结束日期"></a-date-picker>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="备注" label-position="top">
						<a-input v-model="formData.remark" placeholder="请输入备注"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="完成进度" label-position="top">
						<a-input v-model="formData.process" type="Number" placeholder="请输入完成进度"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
		<a-row :gutter="15">
			<a-col>
				<editor v-model="formData.content" @input="e => (formData.content = e)" />
			</a-col>
		</a-row>
		<a-row style="margin-top:20px" :gutter="15">
			<a-col span="10">
				<attachUpload :value="formData.attachment" @input="e => (formData.attachment = e)"></attachUpload>
			</a-col>
		</a-row>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import attachUpload from '@/components/common/attach-upload.vue';
import utils from '@/common/utils';
import editor from '@/components/component/editor.vue';
import moment from 'moment';
import selectDepart from '@/components/common/select-depart.vue';
export default {
	components: { selectStaff, editor, attachUpload, selectDepart },
	mounted() {
		// console.log(apiUtil.getUser());
	},
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			statusList: ['未开始', '进行中', '已完成'],
			afftsId: apiUtil.getUser().staffId,
			organList: apiUtil.getOrganList(),
			// organId: apiUtil.getOrganId(),
			isAlldep: 1,
			formData: {
				organId: apiUtil.getOrganId(''),
				attachment: [],
				categoryId: '',
				content: '',
				isAll: 1,
				overDate: null,
				planId: '',
				process: 0,
				remark: '',
				scopeDepIds: [],
				scopeLeaderIds: [],
				scopeMasterIds: [],
				scopeStaffIds: [],
				startDate: null,
				status: '未开始',
				title: ''
			},
			formRule: {},
			CategoryList: []
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getCategoryList();
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					const formData = JSON.parse(JSON.stringify(form));
					this.formData = formData;
					this.formData.startDate = moment(formData.startDate, 'YYYY-MM-DD');
					this.formData.overDate = moment(formData.overDate, 'YYYY-MM-DD');
					if (this.formData.isAll) {
						this.isAlldep = 1;
					} else {
						this.isAlldep = 2;

					}
				} else {
					this.initformData();
				}
			});
		},
		changeor() {
			this.getCategoryList();
		},
		_close() {
			this.initformData();
			this.show = false;
			this.$emit('close');
		},
		initformData() {
			this.formData.attachment = [];
			this.formData.categoryId = '';
			this.formData.content = '';
			this.formData.isAll = true;
			this.formData.overDate = null;
			this.formData.planId = '';
			this.formData.process = 0;
			this.formData.remark = '';
			this.formData.scopeDepIds = [];
			this.formData.scopeLeaderIds = [];
			this.formData.scopeMasterIds = [];
			this.formData.scopeStaffIds = [];
			this.formData.startDate = null;
			this.formData.status = '未开始';
			this.formData.title = '';
			this.isAlldep = 1;
		},
		//获取分类
		getCategoryList() {
			if (this.formData.organId == 0) {
				this.formData.organId = apiUtil.getDefaultOrganId();
			}
			this.http.get('/platform/work-plan/category', { organId: this.formData.organId }).then(ret => {
				if (ret.code == 200) {
					this.CategoryList = ret.data;
				}
			});
		},

		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					if (this.isAlldep == 1) {
						this.formData.isAll = true;
					} else {
						this.formData.isAll = false;
					}
					this.http.post('/platform/work-plan/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								this.show = false;
							});
						}
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
